import { combineReducers } from '@reduxjs/toolkit';

import { DefaultStore } from '../core/model/store.model';
import userReducer from '../slices/userSlice';
import toasterReducer from '../slices/toasterSlice';
import authReducer from '../slices/authSlice';
import userCrudReducer from '../slices/addUserSlice';
import traineeCrudReducer from '../slices/traineeSlice';
import componentCrudReducer from '../slices/addComponentSlice';
import projectReducer from '../slices/addProjectSlice';
import dashboardReducer from '../slices/dashboardSlice';
import calendarReducer from '../slices/calendarSlice';
import birthdayReducer from '../slices/birthdaySlice';
import profileReducer from '../slices/profileDataSlice';
import interviewsReducer from '../slices/interviewSlice';
import trainingDetailsReducer from '../slices/trainingDetailsSlice';
import trainingReviewReducer from '../slices/trainingReviewSlice';
import panelistsReducer from '../slices/panelistSlice';
import officeLogReducer from '../slices/officeLogSlice';

const reducerList: DefaultStore = {
    user: userReducer,
    toaster: toasterReducer,
    auth: authReducer,
    userCrud: userCrudReducer,
    traineeCrud: traineeCrudReducer,
    componentCrud: componentCrudReducer,
    projectCrud: projectReducer,
    dashboard: dashboardReducer,
    calendar: calendarReducer,
    birthday: birthdayReducer,
    profile: profileReducer,
    interview: interviewsReducer,
    trainingDetails: trainingDetailsReducer,
    trainingReview: trainingReviewReducer,
    panelist: panelistsReducer,
    officeLog: officeLogReducer,
};

const rootReducer = combineReducers(reducerList);

export default rootReducer;
