import { refresh } from '../../slices/authSlice';
import { logoutInterceptor } from '../../slices/authSlice';
import { showToaster } from '../../slices/toasterSlice';
import store from '../../store/index';
import axios from './index';

let isRefreshing = false;

export const onResponse = (response) => {
    /**
     * Do stuff like modifying the data strucutre or position or renaming
     *
     * Read more on requestInterceptor here:
     * https://github.com/axios/axios#interceptors
     */

    const customResponse = { ...response /* add modifications here */ };

    return customResponse.data;
};

export const onResponseError = async (error) => {
    /**
     * Do stuff creating custom errors or modify errors on status 500 or 400
     * or, logout, (location.reload('/login')) if session timedout or status 401
     *
     * Read more on requestInterceptor here:
     * https://github.com/axios/axios#interceptors
     */

    /**
     * Error object cannot be cloned
     * so modify it as such or read parts of it
     * to create a new Error object
     */

    /* eslint-disable-next-line no-param-reassign */
    const { dispatch }: any = store;
    if (error.response && error.response.status === 401 && !isRefreshing) {
        try {
            isRefreshing = true;
            const resp = await dispatch(refresh());
            error.response.config.headers.Authorization = `Bearer ${resp.data.token}`;
            isRefreshing = false;
            return axios(error.response.config);
        } catch (err) {
            dispatch(logoutInterceptor());
        }
    } else if (error.message === 'Network Error') {
        dispatch(
            showToaster({
                message: error?.message || 'Something went wrong! Please try again later.',
            }),
        );
    }
    return Promise.reject(error);
};
