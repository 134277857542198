import axios from '../core/axios/index';
import { createSlice, createAction } from '@reduxjs/toolkit';
import { showToaster } from './toasterSlice';
import { authStart, authFinished, authFail } from './authSlice';

const initialState = {
    loading: false,
    employeeSplitUpLoading: false,
    benchedEmployeeSplitUpLoading: false,
    totalUsers: 0,
    allocatedUserCount: 0,
    billableUserCount: 0,
    unallocatedUserCount: 0,
    shadowUserCount: 0,
    projectCount: 0,
    upcomingProjectCount: 0,
    projectTypeCount: [],
    splittedUserSkillCount: [],
    splittedAllocatedUserSkillCount: [],
    splittedUnAllocatedUserSkillCount: [],
    splittedUserDesignationCount: [],
    benchedEmployeeDesignationCount: [],
    projectTechnologyCount: [],
};

const dashboard = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        getDashboardDataStart(state) {
            state.loading = true;
        },
        getDashboardDataEnd(state: any, action) {
            state.loading = false;
            state.totalUsers = parseInt(action.payload.totalUsers);
            state.allocatedUserCount = parseInt(action.payload.allocatedUserCount);
            state.billableUserCount = parseInt(action.payload.billableUserCount);
            state.unallocatedUserCount = parseInt(action.payload.unallocatedUserCount);
            state.bookedUserCount = parseInt(action.payload.bookedUserCount);
            state.projectCount = parseInt(action.payload.projectCount);
            state.projectTypeCount = action.payload.projectTypeCount;
            state.upcomingProjectCount = parseInt(action.payload.upcomingProjectData) || 0;
            state.shadowUserCount = parseInt(action.payload.shadowUserCount);
            state.splittedAllocatedUserSkillCount = action.payload.splittedAllocatedUserSkillCount;
            state.splittedUnAllocatedUserSkillCount = action.payload.splittedUnAllocatedUserSkillCount;
            state.splittedUserSkillCount = action.payload.splittedUserSkillCount;
            state.projectTechnologyCount = action.payload.projectTechnologyCount;
            state.lateralTraineeCount = action.payload.lateralTraineeCount || 0;
            state.fresherTraineeCount = action.payload.fresherTraineeCount || 0;
        },
        getEmployeeSplitUpStart(state) {
            state.employeeSplitUpLoading = true;
        },
        getEmployeeSplitUpEnd(state, action) {
            state.employeeSplitUpLoading = false;
            state.splittedUserDesignationCount = action.payload;
        },
        getBenchedEmployeeSplitUpStart(state) {
            state.benchedEmployeeSplitUpLoading = true;
        },
        getBenchedEmployeeSplitUpEnd(state, action) {
            state.benchedEmployeeSplitUpLoading = false;
            state.benchedEmployeeDesignationCount = action.payload;
        },
    },
});

export const {
    getDashboardDataStart,
    getDashboardDataEnd,
    getEmployeeSplitUpStart,
    getEmployeeSplitUpEnd,
    getBenchedEmployeeSplitUpStart,
    getBenchedEmployeeSplitUpEnd,
} = dashboard.actions;

export const getDashboardData = () => async (dispatch) => {
    try {
        dispatch(authStart());
        dispatch(getDashboardDataStart());
        const response = await axios.get('dashboard');
        dispatch(getDashboardDataEnd(response?.data));
        dispatch(authFinished());
    } catch (error) {
        dispatch(authFail(error));
        if (error.message !== 'Network Error' && error.message !== 'Request failed with status code 401') {
            dispatch(
                showToaster({
                    message: 'Unable to fetch dashboard data. Please try again.',
                }),
            );
        }
    }
};

export const getEmployeeSplitUp = (type) => async (dispatch) => {
    try {
        dispatch(getEmployeeSplitUpStart());
        const response = await axios.get('dashboard/employee', { params: { type } });
        dispatch(getEmployeeSplitUpEnd(response.data));
    } catch (error) {
        dispatch(
            showToaster({
                message: 'Unable to fetch dashboard data. Please try again.',
            }),
        );
    }
};

export const getBenchedEmployeeSplitUp = (type) => async (dispatch) => {
    try {
        dispatch(getBenchedEmployeeSplitUpStart());
        const response = await axios.get('dashboard/employee-in-bench', { params: { type } });
        dispatch(getBenchedEmployeeSplitUpEnd(response.data));
    } catch (error) {
        dispatch(
            showToaster({
                message: 'Unable to fetch dashboard data. Please try again.',
            }),
        );
    }
};

export default dashboard.reducer;
