import axios from '../core/axios/index';
import { createSlice } from '@reduxjs/toolkit';
import { showToaster } from './toasterSlice';
import { authStart, authFinished, authFail } from './authSlice';
import Api from '../core/config/apiUrls';

const initialState = {
    trainingReviews: {},
    assignmentData: {},
};

const trainingReview = createSlice({
    name: 'trainingReview',
    initialState,
    reducers: {
        setDetails(state: any, action) {
            state.trainingReviews = action.payload.data?.topicAssociations;
            state.assignmentData = action.payload?.data?.topicAssociations.reduce((obj, item) => {
                item?.assignments?.forEach((assignment) => {
                    obj[assignment.assignmentAsnId] = { ...assignment };
                });
                return obj;
            }, {});
        },
        updateAssignmentData(state: any, action) {
            const { id, key, value } = action.payload;
            state.assignmentData = {
                ...state.assignmentData,
                [id]: { ...state.assignmentData[id], [key]: value },
            };
        },
        clearTrainingReviews(state: any) {
            state.trainingReviews = {};
            state.assignmentData = {};
        },
    },
});

export const { setDetails, updateAssignmentData, clearTrainingReviews } = trainingReview.actions;
export default trainingReview.reducer;

export const getTrainingReviews = (userId, data) => async (dispatch) => {
    try {
        dispatch(authStart());
        const response: any = await axios.get(`${Api.assignmentListAPI}${userId}/topics`);
        dispatch(authFinished());
        dispatch(setDetails(response));
        return response;
    } catch (error) {
        dispatch(authFail(error));
        dispatch(
            showToaster({
                message: (error as any)?.response?.data?.error?.message
                    ? (error as any)?.response?.data?.error?.message
                    : (error as any)?.response?.data?.message,
            }),
        );
        return error;
    }
};

export const updateTrainingReview = (assignmentAsnId, data, successCallback) => async (dispatch) => {
    try {
        dispatch(authStart());
        const response: any = await axios.patch(`${Api.updateAssignmentAPI}${assignmentAsnId}`, data);
        dispatch(authFinished());
        dispatch(
            showToaster({
                message: response.message,
                type: 'success',
            }),
        );
        successCallback?.();
    } catch (error) {
        dispatch(authFail(error));
        dispatch(
            showToaster({
                message: (error as any)?.response?.data?.error?.message
                    ? (error as any)?.response?.data?.error?.message
                    : (error as any)?.response?.data?.message || 'Unknown error occurred!',
            }),
        );
        return error;
    }
};
