import { authFail } from '../../slices/authSlice';
import { showToaster } from '../../slices/toasterSlice';

export const handleError = (dispatch: any, error: any) => {
    const errorMessage = error.response?.data?.error?.message
        ? error.response?.data?.error?.message
        : error.response?.data?.message;
    dispatch(authFail(error));
    dispatch(
        showToaster({
            message: errorMessage === 'jwt expired' ? 'Sessions expired' : errorMessage,
        }),
    );
};
