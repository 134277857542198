const Api = {
    skillset: 'project/tag?limit=20&skip=0&search=',
    skillsetWithoutSearchParam: 'project/tag?limit=20&skip=0',
    participantList: 'user/list?limit=20&skip=0&search=',
    participantListWithoutSearch: 'user/list?limit=20&skip=0',
    eventBaseURL: 'event',

    interviewBaseURL: 'interview',
    fetchInterviewReports: 'reports.json', // TODO
    listInterviews: '/list',
    addInterviews: '/create',
    deleteInterviews: '/remove',
    fetchInterviewOptions: '/options',
    fetchTraineeList: 'training/users/',
    fetchMentorsList: 'user/list?isMentor=true&limit=1000&skip=0', // Limit set to 1000

    assignmentListAPI: 'review/user/',
    updateAssignmentAPI: 'review/assignment/',

    officeLogBaseURL: 'attendance',
    listOfficeLog: '/users/',
};

export default Api;
