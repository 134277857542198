import React, { Suspense, lazy } from 'react';
import { withRouter, Switch, Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { DefaultStore } from './core/model/store.model';
import LoaderSpinner from './components/LoaderSpinner/component';
import Page404 from './pages/Page404';

const waitFor = (Tag) => (props) => <Tag {...props} />;
const Login = lazy(() => import('./pages/Login/index'));
const Home = lazy(() => import('./pages/Home'));

const Routes = (props) => {
    const isSignedIn = useSelector((state: DefaultStore) => state.auth.isSignedIn);

    const { location } = props;
    const listofPages = ['/', '/login'];
    if (listofPages.indexOf(location.pathname) > -1) {
        return (
            <Suspense fallback={<LoaderSpinner show={true}></LoaderSpinner>}>
                <Switch location={location}>
                    <Route path="/" component={waitFor(Login)} />
                    <Route path="/login" component={waitFor(Login)} />
                </Switch>
            </Suspense>
        );
    }
    return (
        <Suspense fallback={<LoaderSpinner show={true}></LoaderSpinner>}>
            <LoaderSpinner show={false}/>
            <Switch location={location}>
                <PrivateRoute path="/home" component={waitFor(Home)} access={isSignedIn} />
                <Route path="/invalid-service" component={waitFor(Page404)} />
                <Route component={waitFor(Page404)} />
            </Switch>
        </Suspense>
    );
};

const PrivateRoute = ({ component: Component, access, ...rest }) => (
    <Route {...rest} render={(props) => (access === true ? <Component {...props} /> : <Redirect to="/login" />)} />
);

Routes.propTypes = {
    location: PropTypes.object,
};
export default withRouter(Routes);
