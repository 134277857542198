const dev = {
    API_BASE: 'https://api.feed-dev.qburst.build/api/v1/',
    s3: {
        BUCKET: '',
    },
    MAP: {},
    SOCIAL_MEDIA: {},
};

const prod = {
    API_BASE: 'https://api.feed.qburst.build/api/v1/',

    s3: {
        BUCKET: '',
    },
    MAP: {},
    SOCIAL_MEDIA: {},
};

const config = process.env.REACT_APP_STAGE === 'production' ? prod : dev;

export default config;
