import axios from '../core/axios/index';
import { createSlice } from '@reduxjs/toolkit';
import { showToaster } from './toasterSlice';
import { authStart, authFinished, authFail } from './authSlice';

const initialState = {
    trainingDetails: {},
    subTopicData: {},
};

const trainingDetails = createSlice({
    name: 'trainingDetails',
    initialState,
    reducers: {
        setDetails(state: any, action) {
            state.trainingDetails = action.payload.data;
            state.subTopicData = action.payload?.data?.topicAssociations?.reduce((obj, item) => {
                item?.subTopics?.forEach(x => {
                    obj[x.subTopicAsnId] = { ...x };
                })
                return obj;
            },{})
        },
        updateSubTopicData(state: any, action) {
            const { id, key, value } = action.payload;
            if(!state?.trainingDetails?.learningOverview?.trainingStartDate){
                if(key === 'subTopicStatus' && ['In Progress','Completed'].includes(value)){
                    const weeks = new Set(state.trainingDetails?.topicAssociations?.map(x => x.week) || []).size;
                    const currentDate = new Date();
                    const endDate = new Date();
                    endDate.setDate(endDate.getDate() + ( weeks * 7 ));
                    state.trainingDetails.learningOverview.trainingStartDate = currentDate.toISOString();
                    state.trainingDetails.learningOverview.trainingEndDate = endDate.toISOString();
                }
            }
            state.subTopicData = {
                ...state.subTopicData,
                [id]: { ...state.subTopicData[id], [key]: value }
            }
        },
        clearTrainingDetails(state: any) {
            state.trainingDetails = {}
            state.subTopicData = {}
        }
    },
});

export const { setDetails, updateSubTopicData, clearTrainingDetails } = trainingDetails.actions;
export default trainingDetails.reducer;

export const getTrainingDetails = (url, data) => async (dispatch) => {
    try {
        dispatch(authStart());
        const response: any = await axios.get(url);
        dispatch(authFinished());
        const payload = {
            data: response?.data,
        };
        dispatch(setDetails(payload));
        return response.data;
    } catch (error) {
        dispatch(authFail(error));
        dispatch(
            showToaster({
                message: (error as any)?.response?.data?.error?.message
                    ? (error as any)?.response?.data?.error?.message
                    : (error as any)?.response?.data?.message,
            }),
        );
        return error;
    }
};

export const updateTraining = (url, data, successCallback) => async (dispatch) => {
    try {
        dispatch(authStart());
        const response: any = await axios.patch(url, data);
        dispatch(authFinished());
        successCallback();
        dispatch(
            showToaster({
                message: response.message,
                type: 'success',
            }),
        );
    } catch (error) {
        dispatch(authFail(error));
        dispatch(
            showToaster({
                type: 'error',
                message: (error as any)?.response?.data?.error?.message
                    ? (error as any)?.response?.data?.error?.message
                    : (error as any)?.response?.data?.message || 'Unknown error occured!',
            }),
        );
        return error;
    }
};