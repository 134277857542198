import { createSlice } from '@reduxjs/toolkit';
import axios from '../core/axios/index';
import { handleError } from '../core/utils/sliceHelper';
import { authFinished, authStart } from './authSlice';
import { showToaster } from './toasterSlice';

type initProps = {
    loggedInUser: any;
};

const initialState: initProps = {
    loggedInUser: {},
};
const profileData = createSlice({
    name: 'profile',
    initialState,
    reducers: {
        userProfile(state: any, action) {
            state.loggedInUser = action.payload.data;
        },
    },
});

export const { userProfile } = profileData.actions;

export default profileData.reducer;

export const getUserProfile = (url: string, callback?: any) => async (dispatch) => {
    try {
        dispatch(authStart());
        const response: any = await axios.get(url);
        dispatch(authFinished());
        const payload = {
            data: response.data,
        };
        dispatch(userProfile(payload));
        if(callback) callback(response?.data);
        return response;
    } catch (error) {
        handleError(dispatch, error);
        return error;
    }
};

export const saveUserBirthday = (url: string, data) => async (dispatch) => {
    try {
        dispatch(authStart());
        const { id, ...remaining } = data;
        const response: any = await axios.patch(url, remaining);
        dispatch(authFinished());
        dispatch(getUserProfile('user/' + id));
        dispatch(
            showToaster({
                message: 'Date of Birth is updated successfully',
                type: 'success',
            }),
        );
    } catch (error) {
        handleError(dispatch, error);
        return error;
    }
};

export const saveUserProfilePic = (url: string, data) => async (dispatch) => {
    try {
        dispatch(authStart());
        const { id, ...remaining } = data;
        const response: any = await axios.patch(url, remaining);
        dispatch(authFinished());
        dispatch(getUserProfile('user/' + id));
    } catch (error) {
        handleError(dispatch, error);
        return error;
    }
};
