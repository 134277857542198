import { createSlice } from '@reduxjs/toolkit';

import { UserAuth } from '../core/model/user.model';
import { getAccessToken, getUserName, setUserSession, removeUserSession } from '../core/utils/sessionHandler';
import axios from '../core/axios/index';
import { showToaster } from './toasterSlice';
// import { createBrowserHistory } from 'history';

// export const history = createBrowserHistory();
import { history } from '../core/helpers/history';

const accessToken = getAccessToken();
const initialState: UserAuth = {
    name: getUserName() || '',
    accessToken: accessToken || null,
    isSignedIn: Boolean(accessToken),
    loading: false,
    error: null,
    _id: '',
};

const auth = createSlice({
    name: 'user',
    initialState,
    reducers: {
        authStart(state) {
            state.loading = true;
        },
        authFinished(state) {
            state.loading = false;
        },
        authSuccess(state, action) {
            const { name, token, email, picture, refreshToken, role, _id } = action.payload;
            state.name = name;
            state.accessToken = token;
            state.isSignedIn = true;
            state.loading = false;
            state._id = _id;
            setUserSession({ name, accessToken: token, email, picture, refreshToken, role, _id });
        },
        authFail(state, action) {
            state.error = action.payload;
            state.loading = false;
        },
        getLogoutSuccess(state) {
            state.name = '';
            state.accessToken = null;
            state.isSignedIn = false;
            removeUserSession();
        },
    },
});

export const { authStart, authSuccess, authFail, getLogoutSuccess, authFinished } = auth.actions;
export default auth.reducer;

export const signIn = (url) => async (dispatch) => {
    try {
        dispatch(authStart());
        const response = await axios.get(url);
        const payload = {
            name: response.data.name,
            token: response.data.token,
            email: response.data.email,
            picture: response.data.picture,
            _id: response.data.user.id,
            refreshToken: response.data.refreshToken,
            role: response.data.user.roles[0].role.roleCode,
        };
        dispatch(authSuccess(payload));
    } catch (error) {
        dispatch(authFail(error));
        dispatch(
            showToaster({
                message: 'Unable to sign in. Please try again.',
            }),
        );
    }
};

export const refresh = () => async (dispatch) => {
    try {
        const loggedUser = JSON.parse(localStorage.getItem('Feed_portal') || '{}');
        const refreshToken = loggedUser.refreshToken;
        const role = loggedUser.role;
        const picture = loggedUser.picture;
        const email = loggedUser.email;
        const name = loggedUser.name;
        const response = await axios.get('sso/auth/refresh?refreshToken=' + refreshToken);
        const payload = {
            name: name,
            token: response.data.token,
            email: email,
            picture: picture,
            refreshToken: response.data.refreshToken,
            role: role,
            _id: response.data.id,
        };
        dispatch(authSuccess(payload));
        return response;
    } catch (error) {
        dispatch(authFail(error));
        if (error.message !== 'Request failed with status code 401') {
            dispatch(
                showToaster({
                    message: 'Unable to sign in. Please try again.',
                }),
            );
        }
        return error;
    }
};

export const authCheckState = () => {
    return (dispatch) => {
        const token = localStorage.getItem('token');
        if (!token) {
        } else {
            dispatch(authSuccess(token));
        }
    };
};

export const logoutEvent = () => async (dispatch) => {
    try {
        await axios.get('sso/auth/logout');
        dispatch(getLogoutSuccess());
        dispatch(showToaster({ message: 'Sign out successful' }));
        history.push('/login');
    } catch (error) {
        dispatch(
            showToaster({
                message: error.response?.data?.error?.message
                    ? error.response?.data?.error?.message
                    : error.response?.data?.message,
            }),
        );
    }
};

export const logoutInterceptor = () => (dispatch) => {
    dispatch(getLogoutSuccess());
    dispatch(
        showToaster({
            message: 'Session expired',
        }),
    );
    history.push('/login');
};
