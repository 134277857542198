import moment from 'moment';

export const formatUtcDate = (data) => new Date(moment(data).utc().format('MM/DD/YYYY HH:mm:ss'));

export const formatDateTime = (date) => moment(date).format('MM/DD/YYYY hh:mm:ss a');
export const formatDate = (date,format='MM/DD/YYYY') =>{ 
    if (moment(date).isValid()) return moment(date).format(format);
};

export const convertDate = (type, options) => {
    switch (type) {
        case 'add':
            return moment(options?.date).add(options?.count, options?.key).format(options?.formatType);
        case 'subtract':
            return moment(options?.date).subtract(options?.count, options?.key).format(options?.formatType);
        case 'endOf':
            return moment(options?.date).endOf(options?.key).format(options?.format);
        case 'startOf':
            return moment(options?.date).startOf(options?.key).format(options?.format);
        default:
    }
};

export const convertMonth = (type = '', options: any = {}) => {
    switch (type) {
        case 'add':
            return moment(options?.date).add(options?.count, 'months').format(options?.format);
        case 'subtract':
            return moment(options?.date).subtract(options?.count, 'months').format(options?.format);
        default :
            return moment(options?.date).format(options?.format);
    }
}

export const formatDateTo = (date, toFormat) => moment(date).format(toFormat);

export const formatDateFrom = (date, fromFromat, toFormat) => formatDateTo(moment(date, fromFromat),toFormat);

export const findRangeAndFormatTo = (date, rangeType, range, toFormat?) => toFormat ? formatDateTo(moment(date)[rangeType](range),toFormat) : moment(date)[rangeType](range)._d;

export const calculateAndFormatTo = (date, operation, noOfType, type, toFormat?) => toFormat ? formatDateTo(moment(date)[operation](noOfType, type),toFormat) : moment(date)[operation](noOfType, type)._d;

export const isFutureDate = (date) => moment(date).isAfter(moment());

export const isPastDate = (date) => moment(date).isBefore(moment());

export const today = () => moment();

export const getMonth = (date) => moment(date).format('MMMM');

export const getYear = (date) => moment(date).format('YYYY');