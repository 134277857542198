import React, { useEffect, useState } from 'react';
import { MainContainer } from './styles';
import { useSelector } from 'react-redux';
import { DefaultStore } from '../../core/model/store.model';
import theme from '../../assets/color-theme';

const Loader = ({ show }) => {
    const loading = useSelector((state: DefaultStore) => state.auth.loading);
    const [showLoader, setShowLoader] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            if (loading) {
                // Show the loader after 2 seconds if the API call is still in progress
                setShowLoader(true);
            }
        }, 2000);
        return () => {
            clearTimeout(timer);
            setShowLoader(false);
        };
    }, [loading]);

    if (!loading && !show) {
        return null;
    }

    return (
        <>
            {showLoader && (
                <MainContainer>
                    <div className="spinner-container">
                        <svg className="spinner-loader" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 340 340">
                            <circle cx="170" cy="170" r="160" stroke="#172B3B" />
                            <circle cx="170" cy="170" r="135" stroke={theme.primary} />
                            <circle cx="170" cy="170" r="110" stroke="#172B3B" />
                            <circle cx="170" cy="170" r="85" stroke={theme.primary} />
                        </svg>
                    </div>
                </MainContainer>
            )}
        </>
    );
};

export default React.memo(Loader);
