import { createSlice } from '@reduxjs/toolkit';
import axios from '../core/axios/index';
import { handleError } from '../core/utils/sliceHelper';
import { authFinished, authStart } from './authSlice';
import { showToaster } from './toasterSlice';

type initProps = {
    birthdayList: any[];
    templateList: any[];
    emailSending: boolean;
};

const initialState: initProps = {
    birthdayList: [],
    templateList: [],
    emailSending: false,
};
const birthday = createSlice({
    name: 'birthday',
    initialState,
    reducers: {
        birthdayList(state: any, action) {
            state.birthdayList = action.payload.data;
        },
        emailTemplates(state: any, action) {
            state.templateList = action.payload.data;
        },
    },
});

export const { birthdayList, emailTemplates } = birthday.actions;

export default birthday.reducer;

export const listUserBirthdays = (url: string) => async (dispatch) => {
    try {
        dispatch(authStart());
        const response: any = await axios.get(url);
        dispatch(authFinished());
        const payload = {
            data: response.data,
        };
        dispatch(birthdayList(payload));
        return response;
    } catch (error) {
        handleError(dispatch, error);
        return error;
    }
};

export const fetchEmailTemplatesForBirthday = (url) => async (dispatch) => {
    try {
        dispatch(authStart());
        const response: any = await axios.get(url);
        dispatch(authFinished());
        const payload = {
            data: response.data,
        };
        dispatch(emailTemplates(payload));
        return response;
    } catch (error) {
        handleError(dispatch, error);
        return error;
    }
};

export const sendEMailToUsers = (url, data) => async (dispatch) => {
    try {
        dispatch(authStart());
        const response: any = await axios.post(url, data);
        dispatch(
            showToaster({
                message: response.message,
                type: 'success',
            }),
        );
        dispatch(authFinished());
        return response;
    } catch (error) {
        handleError(dispatch, error);
        return error;
    }
};
