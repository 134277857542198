import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    message: '',
    show: false,
    type: '',
};

const toaster = createSlice({
    name: 'toaster',
    initialState,
    reducers: {
        displayToaster(state, action) {
            state.message = action.payload.message;
            state.show = true;
            state.type = action.payload.type || '';
        },
        hideToaster(state) {
            state.message = '';
            state.show = false;
            state.type = '';
        },
    },
});

export const { displayToaster, hideToaster } = toaster.actions;

export const showToaster = (payload) => async (dispatch) => {
    if (payload?.message?.length && payload?.message !== 'jwt expired') {
        dispatch(displayToaster(payload));
    }
};

export default toaster.reducer;
