import axios from '../core/axios/index';
import { createSlice } from '@reduxjs/toolkit';
import { showToaster } from './toasterSlice';
import { authStart, authFinished, authFail } from './authSlice';
import Api from '../core/config/apiUrls';

const initialState = {
    panelistInterviews: {},
    technologyCount: 0,
    currentDateFilterValue: '',
    searchCandidateValue: '',
    filteredInterviewList: [],
    searchOn: false,
    panelistData: [],
    updatedPanelist: [],
    filteredPanelist: [],
};

const panelists = createSlice({
    name: 'panelists',
    initialState,
    reducers: {
        interviewListDataUpdate(state, action) {
            state.panelistInterviews = action.payload.panelistInterviews;
            state.technologyCount = action.payload.technologyCount;
            state.panelistData = action.payload.panelistData;
        },
        setCurrentDateFilterValue(state, action) {
            state.currentDateFilterValue = action.payload ?? '';
        },
        setSearchPanelistValue(state, action) {
            state.searchCandidateValue = action.payload ?? '';
        },
        setFilteredInterviewList(state, action) {
            state.filteredInterviewList = action.payload;
        },
        setUpdatedPanelList(state, action) {
            state.filteredPanelist = action.payload;
            state.updatedPanelist = action.payload;
        },
        setFilteredPanelList(state, action) {
            state.filteredPanelist = action.payload;
        },
    },
});

export const {
    interviewListDataUpdate,
    setCurrentDateFilterValue,
    setSearchPanelistValue,
    setFilteredInterviewList,
    setUpdatedPanelList,
    setFilteredPanelList,
} = panelists.actions;
export default panelists.reducer;
