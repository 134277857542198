import React, { useEffect, useRef } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { useDispatch, useSelector } from 'react-redux';

import { DefaultStore } from '../../core/model/store.model';
import { hideToaster } from '../../slices/toasterSlice';

let timer = 0;

const SnackbarNotification = () => {
    const dispatch = useDispatch();

    const show: boolean = useSelector((state: DefaultStore) => state.toaster.show);

    const message: string = useSelector((state: DefaultStore) => state.toaster.message);

    const type: 'error' | 'info' | 'success' | 'warning' | '' = useSelector(
        (state: DefaultStore) => state.toaster.type,
    );

    const closeSnackbar: Function = () => {
        dispatch(hideToaster());
    };

    const onSnackbarClose = () => {
        clearTimeout(timer);
        closeSnackbar();
    };

    useEffect(() => {
        timer = setTimeout(() => {
            closeSnackbar();
        }, 5000);
    }, [message]);

    return type ? (
        <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={show}>
            <Alert onClose={onSnackbarClose} variant="filled" severity={type}>
                {message}
            </Alert>
        </Snackbar>
    ) : (
        <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={show}
            message={message}
            onClose={onSnackbarClose}
        />
    );
};

export default SnackbarNotification;
