import axios from '../core/axios/index';
import { createSlice } from '@reduxjs/toolkit';
import { showToaster } from './toasterSlice';
import { authStart, authFinished, authFail } from './authSlice';
import { getErrorMessage } from '../core/utils/errorHandling';

const initialState = {
    components: [],
    count: 0,
    updating: false,
};

const addComponents = createSlice({
    name: 'component',
    initialState,
    reducers: {
        componentsList(state: any, action) {
            state.components = action.payload.data;
            state.count = action.payload.count;
        },
        componentsCrudStart(state) {
            state.updating = true;
        },
        componentsCrudEnd(state) {
            state.updating = false;
            state.components = [];
        },
        componentsListSearch(state, action) {
            state.components = action.payload.data;
            state.count = action.payload.count;
        },
    },
});

export const { componentsList, componentsCrudStart, componentsCrudEnd, componentsListSearch } = addComponents.actions;
export default addComponents.reducer;

export const addComponentFunction = (url, data) => async (dispatch) => {
    try {
        dispatch(authStart());
        dispatch(componentsCrudStart());
        const response: any = await axios.post(url, data);
        dispatch(componentsCrudEnd());
        dispatch(authFinished());
        dispatch(
            showToaster({
                message: response.message,
                type: 'success',
            }),
        );
    } catch (error) {
        dispatch(authFail(error));
        dispatch(componentsCrudEnd());
        dispatch(
            showToaster({
                message: getErrorMessage(error),
            }),
        );
    }
};

export const clearState = () => (dispatch) => {
    dispatch(componentsCrudEnd());
};

export const editComponentFunction = (url, data) => async (dispatch) => {
    try {
        dispatch(componentsCrudStart());
        dispatch(authStart());
        const response: any = await axios.patch(url, data);
        dispatch(authFinished());
        dispatch(componentsCrudEnd());
        dispatch(
            showToaster({
                message: response.message,
                type: 'success',
            }),
        );
    } catch (error) {
        dispatch(componentsCrudEnd());
        dispatch(authFail(error));
        dispatch(
            showToaster({
                message: getErrorMessage(error),
            }),
        );
    }
};

export const listComponentfuntion = (url) => async (dispatch) => {
    try {
        dispatch(authStart());
        const response: any = await axios.get(url);
        dispatch(authFinished());
        const payload = {
            data: response.data,
            count: response.totalCount,
        };
        dispatch(componentsList(payload));
        return response;
    } catch (error) {
        dispatch(authFail(error));
        dispatch(
            showToaster({
                message: getErrorMessage(error),
            }),
        );
        return error;
    }
};

export const searchComponentfuntion = (url) => async (dispatch) => {
    try {
        dispatch(authStart());
        const response: any = await axios.get(url);
        dispatch(authFinished());
        const payload = {
            data: response.data,
            count: response.totalCount,
        };
        dispatch(componentsListSearch(payload));
    } catch (error) {
        dispatch(authFail(error));
        dispatch(
            showToaster({
                message: getErrorMessage(error),
            }),
        );
        return error;
    }
};

export const deleteComponentfuntion = (url, data) => async (dispatch) => {
    try {
        dispatch(componentsCrudStart());
        dispatch(authStart());
        const response: any = await axios.delete(url, { data });
        dispatch(componentsCrudEnd());
        dispatch(authFinished());
        dispatch(
            showToaster({
                message: response.message,
                type: 'success',
            }),
        );
    } catch (error) {
        dispatch(componentsCrudEnd());
        dispatch(authFail(error));
        dispatch(
            showToaster({
                message: getErrorMessage(error),
            }),
        );
        return error;
    }
};

export const addNotesToComponent = (url, data) => async (dispatch) => {
    try {
        dispatch(componentsCrudStart());
        dispatch(authStart());
        const response: any = await axios.post(url, data);
        dispatch(componentsCrudEnd());
        dispatch(authFinished());
        dispatch(
            showToaster({
                message: response.message,
                type: 'success',
            }),
        );
    } catch (error) {
        dispatch(componentsCrudEnd());
        dispatch(authFail(error));
        dispatch(
            showToaster({
                message: getErrorMessage(error),
            }),
        );
        return error;
    }
};
