export const onRequest = (config) => {
    /**
     * Do stuff like adding JWT token to header
     * or, logout, (location.reload('/login')) if session timedout
     *
     * Read more on requestInterceptor here:
     * https://github.com/axios/axios#interceptors
     */
    if (config.id) {
      config.params = { id: config.id };
    }
    
    const loggedUser = JSON.parse(localStorage.getItem('Feed_portal') || '{}');
    if (loggedUser && loggedUser.accessToken) {
      config.headers.Authorization = `Bearer ${loggedUser.accessToken}`;
    }
    const customConfig = { ...config /* add modifications here */ };
  
    return customConfig;
  };
  
  export const onRequestError = error => Promise.reject(error);