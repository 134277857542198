import axios from '../core/axios/index';
import { createSlice, createAction } from '@reduxjs/toolkit';
import { showToaster } from './toasterSlice';
import { authStart, authFinished, authFail } from './authSlice';
import { getErrorMessage } from '../core/utils/errorHandling';

const initialState = {
    users: [],
    count: 0,
    updating: false,
};

const addUser = createSlice({
    name: 'user',
    initialState,
    reducers: {
        usersList(state: any, action) {
            state.users = action.payload.data;
            state.count = action.payload.count;
        },
        usersCrudStart(state) {
            state.updating = true;
        },
        usersCrudEnd(state) {
            state.updating = false;
        },
        usersListSearch(state, action) {
            state.users = action.payload.data;
            state.count = action.payload.count;
        },
    },
});

export const { usersList, usersCrudEnd, usersCrudStart, usersListSearch } = addUser.actions;
export default addUser.reducer;

export const addUserfuntion = (url, data) => async (dispatch) => {
    try {
        dispatch(authStart());
        dispatch(usersCrudStart());
        const response: any = await axios.post(url, data);
        dispatch(usersCrudEnd());
        dispatch(authFinished());
        dispatch(
            showToaster({
                message: response.message,
                type: 'success',
            }),
        );
    } catch (error) {
        dispatch(authFail(error));
        dispatch(usersCrudEnd());
        dispatch(
            showToaster({
                message: getErrorMessage(error),
            }),
        );
    }
};

export const clearState = () => (dispatch) => {
    dispatch(usersCrudEnd());
};

export const editUserfuntion = (url, data) => async (dispatch) => {
    try {
        dispatch(usersCrudStart());
        dispatch(authStart());
        const response: any = await axios.patch(url, data);
        dispatch(authFinished());
        dispatch(usersCrudEnd());
        dispatch(
            showToaster({
                message: response.message,
                type: 'success',
            }),
        );
    } catch (error) {
        dispatch(usersCrudEnd());
        dispatch(authFail(error));
        dispatch(
            showToaster({
                message: getErrorMessage(error),
            }),
        );
    }
};

export const listUserfuntion = (url) => async (dispatch) => {
    try {
        dispatch(authStart());
        const response: any = await axios.get(url);
        dispatch(authFinished());
        const payload = {
            data: response.data,
            count: response.totalCount,
        };
        dispatch(usersList(payload));
        return response;
    } catch (error) {
        dispatch(authFail(error));
        dispatch(
            showToaster({
                message: getErrorMessage(error),
            }),
        );
        return error;
    }
};

export const searchUserfuntion = (url) => async (dispatch) => {
    try {
        dispatch(authStart());
        const response: any = await axios.get(url);
        dispatch(authFinished());
        const payload = {
            data: response.data,
            count: response.totalCount,
        };
        dispatch(usersListSearch(payload));
    } catch (error) {
        dispatch(authFail(error));
        dispatch(
            showToaster({
                message: getErrorMessage(error),
            }),
        );
        return error;
    }
};

export const deleteUserfuntion = (url, data) => async (dispatch) => {
    try {
        dispatch(usersCrudStart());
        dispatch(authStart());
        const response: any = await axios.delete(url, { data });
        dispatch(usersCrudEnd());
        dispatch(authFinished());
        dispatch(
            showToaster({
                message: response.message,
                type: 'success',
            }),
        );
    } catch (error) {
        dispatch(usersCrudEnd());
        dispatch(authFail(error));
        dispatch(
            showToaster({
                message: getErrorMessage(error),
            }),
        );
        return error;
    }
};

export const addUserToProject = (url, data) => async (dispatch) => {
    try {
        dispatch(usersCrudStart());
        dispatch(authStart());
        const response: any = await axios.post(url, data);
        dispatch(usersCrudEnd());
        dispatch(authFinished());
        dispatch(
            showToaster({
                message: response.message,
                type: 'success',
            }),
        );
    } catch (error) {
        dispatch(usersCrudEnd());
        dispatch(authFail(error));
        dispatch(
            showToaster({
                message: getErrorMessage(error),
            }),
        );
        return error;
    }
};
