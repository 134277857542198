import axios from '../core/axios/index';
import { createSlice, createAction } from '@reduxjs/toolkit';
import { showToaster } from './toasterSlice';
import { authStart, authFinished, authFail } from './authSlice';
import Api from '../core/config/apiUrls';
import { getErrorMessage } from '../core/utils/errorHandling';

const initialState = {
    traineesList: [],
    selectedTrainee: {},
    count: 0,
    updating: false,
    mentorList: [],
};

const traineeListing = createSlice({
    name: 'trainee',
    initialState,
    reducers: {
        traineesList(state, action) {
            state.selectedTrainee = {};
            state.traineesList = action.payload.data;
            state.count = action.payload.count;
        },
        setMentorList(state, action) {
            state.mentorList = action.payload;
        },
        setSelectedTrainee(state, action) {
            state.selectedTrainee = action.payload.data;
        },
        traineeCrudStart(state) {
            state.updating = true;
        },
        traineeCrudEnd(state) {
            state.updating = false;
        },
    },
});

export const {
    traineesList,
    setSelectedTrainee,
    traineeCrudStart,
    traineeCrudEnd,
    setMentorList,
} = traineeListing.actions;
export default traineeListing.reducer;

export const listTrainees = () => async (dispatch) => {
    try {
        dispatch(authStart());
        const response: any = await axios.get(Api.fetchTraineeList);
        dispatch(authFinished());
        const payload = {
            data: response.data.map((trainee) => {
                trainee.mentees = trainee.mentees.map((mentee) => mentee.mentor);
                return trainee;
            }),
            count: response.totalCount,
        };
        dispatch(traineesList(payload));
    } catch (error) {
        dispatch(authFail(error));
        dispatch(
            showToaster({
                message: getErrorMessage(error),
            }),
        );
        return error;
    }
};

export const updateTrainee = (url, data) => async (dispatch) => {
    try {
        dispatch(authStart());
        dispatch(traineeCrudStart());
        const response: any = await axios.patch(url, data);
        dispatch(authFinished());
        dispatch(traineeCrudEnd());
        dispatch(
            showToaster({
                message: response.message,
                type: 'success',
            }),
        );
    } catch (error) {
        dispatch(authFail(error));
        dispatch(traineeCrudEnd());
        dispatch(
            showToaster({
                message: getErrorMessage(error),
            }),
        );
        return error;
    }
};

export const getMentorsList = () => async (dispatch) => {
    try {
        dispatch(authStart());
        const response: any = await axios.get(Api.fetchMentorsList);
        dispatch(authFinished());
        dispatch(setMentorList(response.data));
    } catch (error) {
        dispatch(authFail(error));
        dispatch(
            showToaster({
                message: getErrorMessage(error),
            }),
        );
        return error;
    }
};