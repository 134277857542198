import { UserAuth } from '../model/user.model';
import { Roles } from '../../components/PrivateRoutes/roles';
const SESSION_NAME = 'Feed_portal'; //Replace app with ProjectName

export const setUserSession = (data: UserAuth) => {
    localStorage.setItem(SESSION_NAME, JSON.stringify(data));
};

export const getUserSession = () => {
    const data = localStorage.getItem(SESSION_NAME) || '{}';
    return JSON.parse(data);
};

export const removeUserSession = () => {
    localStorage.removeItem(SESSION_NAME);
}

export const getAccessToken = () => getUserSession().accessToken;

export const getUserName = () => getUserSession().name;

export const isAdmin = () => getUserSession().role === Roles.Admin;

export const isEventOwner = (event) => event.createdBy?.fullName === getUserName();

export const isEventHoster = (event) => event.takenBy?.fullName === getUserName();