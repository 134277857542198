import { createSlice } from '@reduxjs/toolkit';

import { UserAuth } from '../core/model/user.model';
import { getAccessToken, getUserName, setUserSession, removeUserSession } from '../core/utils/sessionHandler';
import axios from '../core/axios/index';
import { showToaster } from './toasterSlice';
import Api from '../core/config/apiUrls';
import { formatUtcDate } from '../core/utils/momentHelper';

const accessToken = getAccessToken();
const initialState = {
    loading: false,
    skillset: [],
    events: Array(),
    selectedEvent: {},
    selectedView: 'month',
    listView: 'calendar',
    dateRange: null,
    filters: {}
};

const calendar = createSlice({
    name: 'calendar',
    initialState,
    reducers: {
        getCalendarDataStart(state) {
            state.loading = true;
        },
        setCalendarEventSuccess(state, action) {
            state.loading = false;
            state.events.push({
                ...action.payload.data,
                startTime: formatUtcDate(action.payload.data.startTime),
                endTime: formatUtcDate(action.payload.data.endTime),
            });
        },
        updateEventSuccess(state, action) {
            state.loading = false;
            state.events = state.events.map((event) => {
                if (event.id === action.payload.data.id) {
                    return {
                        ...action.payload.data,
                        startTime: formatUtcDate(action.payload.data.startTime),
                        endTime: formatUtcDate(action.payload.data.endTime),
                    };
                }
                return event;
            });
        },
        deleteEventSuccess(state, action) {
            state.loading = false;
            state.events = state.events.filter((event) => event.id !== action.payload);
        },
        getCalendarEvents(state, action) {
            state.loading = false;
            const eventList = action.payload.data.events.map((event) => {
                return {
                    ...event,
                    startTime: formatUtcDate(event.startTime),
                    endTime: formatUtcDate(event.endTime),
                };
            });
            state.events = eventList;
            if (action.payload.selectedView !== 'agenda') {
                state.selectedView = action.payload.selectedView;
            }
        },
        setListView(state, action) {
            state.listView = action.payload;
        },
        setSkillset(state, action) {
            state.loading = false;
            state.skillset = action.payload.data;
        },
        setDateRange(state, action) {
            state.dateRange = action.payload;
        },
        setFilters(state, action) {
            state.filters = action.payload;
        },
        clearFilters(state) {
            state.filters = {};
        },
        setEventDetails(state, action) {
            state.loading = false;
            state.selectedEvent = {
                ...action.payload.data,
                startTime: formatUtcDate(action.payload.data.startTime),
                endTime: formatUtcDate(action.payload.data.endTime),
            };
        },
        updateEventStart(state, action) {
            state.loading = true;
        },
        updateEventError(state, action) {
            state.loading = false;
        },
        clearSelectedEvent(state) {
            state.selectedEvent = {};
        },
        clearEvents(state) {
            state.events = [];
        },
        getCalendarDataEnd(state: any, action) {
            state.loading = false;
            state.eventGroups = action.payload.data;
        },
    },
});

export const {
    getCalendarDataStart,
    setCalendarEventSuccess,
    getCalendarEvents,
    getCalendarDataEnd,
    setSkillset,
    setEventDetails,
    setListView,
    setDateRange, 
    deleteEventSuccess,
    updateEventSuccess,
    updateEventStart,
    updateEventError,
    clearSelectedEvent,
    clearEvents,
    setFilters,
    clearFilters
} = calendar.actions;
export default calendar.reducer;

export const setCalendarEvent = (data) => async (dispatch) => {
    try {
        dispatch(getCalendarDataStart());
        const response = await axios.post(Api.eventBaseURL, data);
        dispatch(setCalendarEventSuccess({ ...response }));
    } catch (error) {
        dispatch(
            showToaster({
                message: 'Unable to create event',
            }),
        );
    }
};
export const editCalendarEvent = (data, id) => async (dispatch) => {
    try {
        dispatch(getCalendarDataStart());
        const response = await axios.patch(`${Api.eventBaseURL}/${id}`, data);
        dispatch(setEventDetails({ ...response }));
        dispatch(updateEventSuccess({ ...response }));
    } catch (error) {
        dispatch(
            showToaster({
                message: 'Unable to update event',
            }),
        );
    }
};
export const markAttendance = (data, id, eventId, selectedUser) => async (dispatch) => {
    try {
        const response = await axios.patch(`${Api.eventBaseURL}/status/${id}`, data);
        dispatch(getEventDetails(eventId));
    } catch (error) {
        dispatch(updateEventError(error));
        dispatch(
            showToaster({
                message: 'Unable to mark attendance',
            }),
        );
    }
};
export const deleteCalendarEvent = (id) => async (dispatch) => {
    try {
        dispatch(getCalendarDataStart());
        const response = await axios.delete(`${Api.eventBaseURL}/${id}`);
        dispatch(deleteEventSuccess(id));
    } catch (error) {
        dispatch(
            showToaster({
                message: 'Unable to delete event',
            }),
        );
    }
};
export const getCalendarEventList = (url, data) => async (dispatch) => {
    try {
        dispatch(getCalendarDataStart());
        const response = await axios.get(`${Api.eventBaseURL}${url}`);
        dispatch(
            getCalendarEvents({
                ...response,
                selectedView: data.type,
            }),
        );
    } catch (error) {
        dispatch(
            showToaster({
                message: 'Unable to get events',
            }),
        );
    }
};
export const getSkillset = () => async (dispatch) => {
    try {
        dispatch(getCalendarDataStart());
        const response = await axios.get(Api.skillsetWithoutSearchParam);
        dispatch(setSkillset({ ...response }));
    } catch (error) {
        dispatch(
            showToaster({
                message: 'Unable to get skills',
            }),
        );
    }
};

export const getEventDetails = (id) => async (dispatch) => {
    try {
        const response = await axios.get(`${Api.eventBaseURL}/${id}`);
        dispatch(setEventDetails({ ...response }));
    } catch (error) {
        dispatch(
            showToaster({
                message: 'Unable to get event details',
            }),
        );
    }
};

export const resetSelectedEvent = () => async (dispatch) => {
    dispatch(clearSelectedEvent());
};
