import React from 'react';
import PropTypes from 'prop-types';
import { Nopage } from './style';

const Page404Comp = ({ location }) => (
    // <div>
    //   <h2>404</h2>
    //   <p>{intl.get('app/no_match').d('No match for')} <code>{location.pathname}</code></p>
    // </div>
    <Nopage>
        <div className="nopage-contianer">
            <h1 className="nopage-header">Not found :(</h1>
            <p className="nopage-subheader">
                <code>{location.pathname}</code> . Sorry, but the page you were trying to view does not exist.
            </p>
            <i>404</i>
        </div>
    </Nopage>
);

Page404Comp.displayName = Page404Comp;

export { Page404Comp as default };
