import axios from '../core/axios/index';
import { createSlice, createAction } from '@reduxjs/toolkit';
import { showToaster } from './toasterSlice';
import { authStart, authFinished, authFail } from './authSlice';

const initialState = {
    projects: [],
    count: 0,
    updating: false,
};

const addProject = createSlice({
    name: 'project',
    initialState,
    reducers: {
        projectsList(state, action) {
            state.projects = action.payload.data;
            state.count = action.payload.count;
        },
        projectsCrudStart(state) {
            state.updating = true;
        },
        projectsCrudEnd(state) {
            state.updating = false;
        },
    },
});

export const { projectsList, projectsCrudStart, projectsCrudEnd } = addProject.actions;
export default addProject.reducer;

export const addProjectfuntion = (data) => async (dispatch) => {
    const url = 'project';
    try {
        dispatch(authStart());
        dispatch(projectsCrudStart());
        const response = await axios.post(url, data);
        dispatch(projectsCrudEnd());
        dispatch(authFinished());
    } catch (error) {
        dispatch(projectsCrudEnd());
        dispatch(authFail(error));
        dispatch(
            showToaster({
                message: error.response?.data?.error?.message
                    ? error.response?.data?.error?.message
                    : error.response?.data?.message,
            }),
        );
    }
};

export const editProject = (url, data) => async (dispatch) => {
    try {
        dispatch(authStart());
        dispatch(projectsCrudStart());
        const response: any = await axios.patch(url, data);
        dispatch(projectsCrudEnd());
        dispatch(authFinished());
        dispatch(
            showToaster({
                message: response.message,
                type: 'success',
            }),
        );
    } catch (error) {
        dispatch(projectsCrudEnd());
        dispatch(authFail(error));
        dispatch(
            showToaster({
                message: error.response?.data?.error?.message
                    ? error.response?.data?.error?.message
                    : error.response?.data?.message,
            }),
        );
    }
};

export const listProject = (url) => async (dispatch) => {
    try {
        dispatch(authStart());
        const response: any = await axios.get(url);
        dispatch(authFinished());
        const payload = {
            data: response.data,
            count: response.totalCount,
        };
        dispatch(projectsList(payload));
    } catch (error) {
        dispatch(authFail(error));
        dispatch(
            showToaster({
                message: error.response?.data?.error?.message
                    ? error.response?.data?.error?.message
                    : error.response?.data?.message,
            }),
        );
        return error;
    }
};

export const deleteProject = (url, data) => async (dispatch) => {
    try {
        dispatch(authStart());
        dispatch(projectsCrudStart());
        const response: any = await axios.delete(url, { data });
        dispatch(authFinished());
        dispatch(projectsCrudEnd());
        dispatch(
            showToaster({
                message: response.message,
                type: 'success',
            }),
        );
    } catch (error) {
        dispatch(authFail(error));
        dispatch(projectsCrudEnd());
        dispatch(
            showToaster({
                message: error.response?.data?.error?.message
                    ? error.response?.data?.error?.message
                    : error.response?.data?.message,
            }),
        );
        return error;
    }
};

export const getProjectDetails = (url) => async (dispatch) => {
    try {
        dispatch(authStart());
        const response = await axios.get(url);
        dispatch(authFinished());
        let userData: any = [];
        let tagData: any = [];
        response.data.resources.map((resp: any) => {
            const userDetail = {
                userId: resp.user.id,
                userType: resp.userType,
                userName: resp.user.fullName,
                id: resp.id,
            };
            userData.push(userDetail);
        });
        response.data.technology.map((tech: any) => {
            const tagDetail = {
                id: tech.tag.id,
                tag: tech.tag.tag,
            };
            tagData.push(tagDetail);
        });

        response.data.resources = userData;
        response.data.technology = tagData;
        return response.data;
    } catch (error) {
        dispatch(authFail(error));
        dispatch(
            showToaster({
                message: error.response?.data?.error?.message
                    ? error.response?.data?.error?.message
                    : error.response?.data?.message,
            }),
        );
        return error;
    }
};
