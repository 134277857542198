import axios from '../core/axios/index';
import { createSlice } from '@reduxjs/toolkit';
import { showToaster } from './toasterSlice';
import { authStart, authFinished, authFail } from './authSlice';
import Api from '../core/config/apiUrls';

interface Status {
    createdAt: Date;
    id: string;
    isActive: boolean;
    status: string;
    updatedAt: Date;
}
interface Interview {
    candidateName: string;
    createdAt?: Date;
    createdByEmail?: string;
    finalComments?: string;
    finalStatus?: Array<Status>;
    id: string;
    interviewDate?: Date;
    isActive?: boolean;
    panelComments?: string;
    panelEvaluationStatus?: Array<Status>;
    panellistId?: [{ id?: string; tag?: string; isActive?: boolean; createdAt?: Date; updatedAt?: Date }];
    technologyId?: [{ id?: string; tag?: string; isActive?: boolean; createdAt?: Date; updatedAt?: Date }];
    updatedAt?: Date;
    updatedByEmail?: string;
    yearsOfExperience?: number;
}

interface InterviewList {
    interviewList: Array<Interview>;
    count: number;
    updating: boolean;
    interviewOptionsLoading: boolean;
    interviewPanelists: [];
    interviewStatus: [];
    interviewTechnologies: [];
    interviewReportsSplitUpLoading: boolean;
    currentMonthTechnologyCount: [];
    currentMonthTotal: {
        schedules: number;
        selected: number;
    };
    filteredInterviewList: Array<Interview>;
    searchOn: boolean;
    searchFilteredInterviewList: Array<Interview>;
    currentDateFilterValue: string;
    searchCandidateValue: string;
}

const initialState = {
    interviewList: [],
    count: 0,
    updating: false,
    interviewOptionsLoading: false,
    interviewPanelists: [],
    interviewStatus: [],
    interviewTechnologies: [],
    interviewReportsSplitUpLoading: false,
    currentMonthTechnologyCount: [],
    currentMonthTotal: {
        schedules: 0,
        selected: 0,
    },
    filteredInterviewList: [],
    searchOn: false,
    searchFilteredInterviewList: [],
    currentDateFilterValue: '',
    searchCandidateValue: '',
} as InterviewList;

const interviews = createSlice({
    name: 'interview',
    initialState,
    reducers: {
        interviewList(state, action) {
            state.interviewList = action.payload.data;
            state.filteredInterviewList = action.payload.data;
            state.count = action.payload.count;
        },
        interviewCrudStart(state) {
            state.updating = true;
        },
        interviewCrudEnd(state) {
            state.updating = false;
        },
        setCurrentDateFilterValue(state, action) {
            state.currentDateFilterValue = action.payload ?? '';
        },
        getinterviewOptionsStart(state) {
            state.interviewOptionsLoading = true;
        },
        setSearchCandidateValue(state, action) {
            state.searchCandidateValue = action.payload ?? '';
        },
        getinterviewOptionsEnd(state, action) {
            state.interviewOptionsLoading = false;
            state.interviewPanelists = action.payload.panellist;
            state.interviewStatus = action.payload.status;
            state.interviewTechnologies = action.payload.technologies;
        },
        filterInterview(state, action) {
            const typeOfFilter = action.payload[1];
            switch (typeOfFilter) {
                case 'panelstatus': {
                    const panelStatusToFilter = action.payload[0];
                    if (panelStatusToFilter) {
                        state.filteredInterviewList = state.filteredInterviewList.filter((interview) =>
                            panelStatusToFilter.includes(interview.panelEvaluationStatus?.[0]?.status),
                        );
                    }
                    break;
                }
                case 'finalstatus': {
                    const finalStatusToFilter = action.payload[0];
                    if (finalStatusToFilter) {
                        state.filteredInterviewList = state.filteredInterviewList.filter((interview) =>
                            finalStatusToFilter.includes(interview.finalStatus?.[0]?.status),
                        );
                    }
                    break;
                }
                case 'panellist': {
                    const idsToFilter = action.payload?.[0];
                    if (idsToFilter) {
                        state.filteredInterviewList = state.filteredInterviewList.filter((interview) =>
                            idsToFilter.includes(interview.panellistId?.[0]?.id),
                        );
                    }
                    break;
                }
                case 'technology': {
                    const technologyToFilter = action.payload[0];
                    if (technologyToFilter) {
                        state.filteredInterviewList = state.filteredInterviewList.filter((interview) =>
                            technologyToFilter.includes(interview.technologyId?.[0]?.tag),
                        );
                    }
                    break;
                }
            }
        },
        filterCandidateName(state, action) {
            const candidateToFilter = action.payload;
            if (candidateToFilter.length) {
                state.searchOn = true;
                state.searchFilteredInterviewList = state.filteredInterviewList.filter(
                    (interview) =>
                        interview.candidateName.toLowerCase().indexOf(candidateToFilter.toLowerCase()) !== -1,
                );
            } else {
                state.searchOn = false;
            }
        },
        setFilteredInterviewList(state, action) {
            state.filteredInterviewList = action.payload;
        },
    },
});

export const {
    interviewList,
    interviewCrudStart,
    interviewCrudEnd,
    getinterviewOptionsStart,
    getinterviewOptionsEnd,
    filterInterview,
    setFilteredInterviewList,
    filterCandidateName,
    setCurrentDateFilterValue,
    setSearchCandidateValue,
} = interviews.actions;
export default interviews.reducer;

export const addInterviews = (data) => async (dispatch, getState) => {
    try {
        const currentDateUrl = getState()?.interview?.currentDateFilterValue ?? '';
        dispatch(authStart());
        dispatch(interviewCrudStart());
        const response: any = await axios.post(`${Api.interviewBaseURL}${Api.addInterviews}`, data);
        dispatch(authFinished());
        dispatch(listInterviews(currentDateUrl));
        dispatch(interviewCrudEnd());
        dispatch(
            showToaster({
                message: response.message,
                type: 'success',
            }),
        );
    } catch (error) {
        dispatch(interviewCrudEnd());
        dispatch(authFail(error));
        dispatch(
            showToaster({
                message: error.response?.data?.error?.message
                    ? error.response?.data?.error?.message
                    : error.response?.data?.message || 'Error',
            }),
        );
    }
};

export const editInterviews = (id, data) => async (dispatch, getState) => {
    try {
        const currentDateUrl = getState()?.interview?.currentDateFilterValue ?? '';
        dispatch(authStart());
        dispatch(interviewCrudStart());
        const response: any = await axios.patch(`${Api.interviewBaseURL}/${id}`, data);
        dispatch(authFinished());
        dispatch(listInterviews(currentDateUrl));
        dispatch(interviewCrudEnd());
        dispatch(
            showToaster({
                message: response.message,
                type: 'success',
            }),
        );
    } catch (error) {
        dispatch(interviewCrudEnd());
        dispatch(authFail(error));
        dispatch(
            showToaster({
                message: error.response?.data?.error?.message
                    ? error.response?.data?.error?.message
                    : error.response?.data?.message || 'Error',
            }),
        );
    }
};

export const fetchInterviewOptions = () => async (dispatch) => {
    try {
        dispatch(getinterviewOptionsStart());
        const response: any = await axios.get(`${Api.interviewBaseURL}${Api.fetchInterviewOptions}`);
        dispatch(getinterviewOptionsEnd(response.data));
    } catch (error) {
        dispatch(authFail(error));
        dispatch(
            showToaster({
                message: error.response?.data?.error?.message
                    ? error.response?.data?.error?.message
                    : error.response?.data?.message || 'Error',
            }),
        );
        return error;
    }
};

export const listInterviews = (url = '') => async (dispatch) => {
    try {
        dispatch(authStart());
        const response: any = await axios.get(`${Api.interviewBaseURL}${Api.listInterviews}${url}`);
        dispatch(authFinished());
        const payload = {
            data: response.data,
            count: response.totalCount,
        };
        dispatch(interviewList(payload));
    } catch (error) {
        dispatch(authFail(error));
        dispatch(
            showToaster({
                message: error.response?.data?.error?.message
                    ? error.response?.data?.error?.message
                    : error.response?.data?.message || 'Error',
            }),
        );
        return error;
    }
};

export const deleteInterviews = (data) => async (dispatch, getState) => {
    try {
        const currentDateUrl = getState()?.interview?.currentDateFilterValue ?? '';
        dispatch(authStart());
        dispatch(interviewCrudStart());
        const response: any = await axios.delete(`${Api.interviewBaseURL}${Api.deleteInterviews}`, {
            data: {
                ids: [`${data}`],
            },
        });
        dispatch(authFinished());
        dispatch(listInterviews(currentDateUrl));
        dispatch(interviewCrudEnd());
        dispatch(
            showToaster({
                message: response.message,
                type: 'success',
            }),
        );
    } catch (error) {
        dispatch(authFail(error));
        dispatch(interviewCrudEnd());
        dispatch(
            showToaster({
                message: error.response?.data?.error?.message
                    ? error.response?.data?.error?.message
                    : error.response?.data?.message || 'Error',
            }),
        );
        return error;
    }
};
