import styled from 'styled-components';

export const Nopage = styled.div`

.nopage-contianer {
	margin-top: 100px;
	height: auto;
	text-align: center;
	width: 100%;
}

.nopage-contianer .nopage-header {
	color: #272727;
	font-weight: 600;
	font-size: 18px;
}

.nopage-contianer .nopage-subheader {
	font-size: 16px;
	color: #272727;
	font-weight: 600;
}

.nopage-contianer i {
	font-style: normal;
	font-size: 125px;
	color: #272727;
}

`;