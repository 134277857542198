import axios from '../core/axios/index';
import { createSlice } from '@reduxjs/toolkit';
import { authFail, authFinished, authStart } from './authSlice';
import Api from '../core/config/apiUrls';
import { showToaster } from './toasterSlice';
import { tableSort,getComparator } from '../core/utils/sortHelper';
const initialState = {
    userData: [],
    count: 0,
    timePeriodFilter: '',
    searchString: '',
    user: {},
    sortOrder: 'desc',
    orderBy: 'attendanceCount',
    rows:[],
    userAttendanceCount:0,
    lastDate:'',
    globalCal:[],
};

const officeLog = createSlice({
    name: 'officeLog',
    initialState,
    reducers: {
        officeLogList(state, action) {
            state.userData = action.payload.data;
            state.count = action.payload.count;
            state.lastDate = action.payload.lastDate;
        },
        officeLogFilter(state, action) {
            state.timePeriodFilter = action.payload;
        },
        officeLogSearch(state, action) {
            state.searchString = action.payload;
        },
        setAttendanceDates(state, action) {
            state.user = action.payload.data;
        },
        setOrder(state, action) {
            state.sortOrder = action.payload;
        },
        setOrderBy(state, action) {
            state.orderBy = action.payload;
        },
        setRows(state,action){
            state.rows = action.payload;
        },
        setUserMonthlyAttendanceCount(state,action){
            state.userAttendanceCount = action.payload;
        },
        globalCalDates(state,action){
            state.globalCal = action.payload.data;
        },
    },
});
export const {
    officeLogList,
    setAttendanceDates,
    officeLogFilter,
    officeLogSearch,
    setOrder,
    setOrderBy,
    setRows,
    setUserMonthlyAttendanceCount,
    globalCalDates,
} = officeLog.actions;
export default officeLog.reducer;

export const listofficeLog = (dateRange = '', isGlobalCal) => async (dispatch) => {
    try {
        dispatch(authStart());
        const response: any = await axios.get(`${Api.officeLogBaseURL}${dateRange}`);
        dispatch(authFinished());
        const payload = {
            data: tableSort(response.data?.attendance, getComparator('asc', 'employeeName')),
            count: response.data?.attendance.length,
            lastDate:response.data?.lastAttendanceDate,
        };
        isGlobalCal ? dispatch(globalCalDates(payload)) : dispatch(officeLogList(payload));
    } catch (error) {
        dispatch(authFail(error));
        dispatch(
            showToaster({
                message: error.response?.data?.error?.message
                    ? error.response?.data?.error?.message
                    : error.response?.data?.message || 'Error',
            }),
        );
        return error;
    }
};

export const getCalendarEventList = (id, dateRange) => async (dispatch) => {
    try {
        dispatch(authStart());
        const response = await axios.get(`${Api.officeLogBaseURL}${Api.listOfficeLog}${id}${dateRange}`);
        dispatch(authFinished());
        const payload = {
            data: response.data,
        };
        dispatch(setAttendanceDates(payload));
    } catch (error) {
        dispatch(authFail(error));
        dispatch(
            showToaster({
                message: 'Unable to get attendance details.',
            }),
        );
        return error;
    }
};
