import React, { Component, useEffect, useState } from 'react';
import './App.scss';
import { BrowserRouter, Router } from 'react-router-dom';
import Routes from './Routes';
import { history } from './core/helpers/history';
import SnackbarNotification from './components/SnackbarNotification';
import * as serviceWorker from './serviceWorker';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import PWAPrompt from 'react-ios-pwa-prompt';

function App() {
    const [open, setOpen] = useState(false);
    const [isIOS, setIsIOS] = useState(false);

    useEffect(() => {
        serviceWorker.register({
            onUpdate: (registration) => {
                const waitingServiceWorker = registration.waiting;
                if (waitingServiceWorker) {
                    waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' });
                    setOpen(true);
                }
            },
        });
    });

    useEffect(() => {
        setIsIOS(checkForIOS());
    }, []);

    const reloadPage = () => {
        window.location.reload();
        setOpen(false);
    };

    return (
        <Router history={history}>
            {isIOS && <PWAPrompt />}
            <SnackbarNotification />
            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={open}
                message="A new update is available"
                action={
                    <>
                        <Button color="secondary" size="small" onClick={reloadPage}>
                            RELOAD
                        </Button>
                    </>
                }
            />
            <Routes />
        </Router>
    );
}

export default App;

const checkForIOS = () => {
    const ua = window.navigator.userAgent;
    const webkit = !!ua.match(/WebKit/i);
    const isIPad = !!ua.match(/iPad/i);
    const isIPhone = !!ua.match(/iPhone/i);
    const isIPad13 = !!ua.match(/Mac/i) && window.navigator.maxTouchPoints > 1;
    const isIOS = isIPad || isIPhone || isIPad13;
    const isSafari = isIOS && webkit && !ua.match(/CriOS/i);
    return isSafari;
};
